export default [
  // {
  //   label: 'ID',
  //   field: 'id',
  // },
  {
    label: 'Name',
    field: 'name',
  },
  {
    label: 'Referrals',
    field: 'referrals',
  },
  {
    label: 'Reward Multiplier',
    field: 'rewardMultiplier',
  },
  {
    label: 'Action',
    field: 'action',
  },
]

export const purchasesTable = [
  {
    label: 'Item',
    field: 'item',
  },
  {
    label: 'Price, USDT',
    field: 'price',
  },
  {
    label: 'HP Price',
    field: 'hpPrice',
  },
  {
    label: 'Diamond price',
    field: 'diamondPrice',
  },
  {
    label: 'Wallet tasks price',
    field: 'walletTasksPrice',
  },
  {
    label: 'Qty',
    field: 'quantity',
  },
  {
    label: 'Action',
    field: 'action',
  },
]
